export default [
    {"name": "", "value": "*:*"},

    {"name": "", "value": "schools:*"},
    {"name": "", "value": "schools:manage"},
    {"name": "", "value": "schools:create"},
    {"name": "", "value": "schools:update"},
    {"name": "", "value": "schools:import"},
    {"name": "", "value": "schools:delete"},

    {"name": "", "value": "departments:*"},
    {"name": "", "value": "departments:manage"},
    {"name": "", "value": "departments:create"},
    {"name": "", "value": "departments:update"},
    {"name": "", "value": "departments:import"},
    {"name": "", "value": "departments:delete"},

    {"name": "", "value": "programmes:*"},
    {"name": "", "value": "programmes:manage"},
    {"name": "", "value": "programmes:create"},
    {"name": "", "value": "programmes:update"},
    {"name": "", "value": "programmes:import"},
    {"name": "", "value": "programmes:delete"},

    {"name": "", "value": "blocks:*"},
    {"name": "", "value": "blocks:manage"},
    {"name": "", "value": "blocks:create"},
    {"name": "", "value": "blocks:update"},
    {"name": "", "value": "blocks:import"},
    {"name": "", "value": "blocks:delete"},

    {"name": "", "value": "rounds:*"},
    {"name": "", "value": "rounds:manage"},
    {"name": "", "value": "rounds:create"},
    {"name": "", "value": "rounds:update"},
    {"name": "", "value": "rounds:import"},
    {"name": "", "value": "rounds:delete"},

    {"name": "", "value": "plots:*"},
    {"name": "", "value": "plots:manage"},
    {"name": "", "value": "plots:create"},
    {"name": "", "value": "plots:update"},
    {"name": "", "value": "plots:import"},
    {"name": "", "value": "plots:delete"},

    {"name": "", "value": "itineraries:*"},
    {"name": "", "value": "itineraries:manage"},
    {"name": "", "value": "itineraries:create"},
    {"name": "", "value": "itineraries:update"},
    {"name": "", "value": "itineraries:import"},
    {"name": "", "value": "itineraries:delete"},
    {"name": "", "value": "itineraries:configure"},

    {"name": "", "value": "properties:*"},
    {"name": "", "value": "properties:manage"},
    {"name": "", "value": "properties:create"},
    {"name": "", "value": "properties:update"},
    {"name": "", "value": "properties:import"},
    {"name": "", "value": "properties:delete"},

    {"name": "", "value": "customers:*"},
    {"name": "", "value": "customers:manage"},
    {"name": "", "value": "customers:create"},
    {"name": "", "value": "customers:update"},
    {"name": "", "value": "customers:import"},
    {"name": "", "value": "customers:delete"},

    {"name": "", "value": "voucher-classes:*"},
    {"name": "", "value": "voucher-classes:manage"},
    {"name": "", "value": "voucher-classes:create"},
    {"name": "", "value": "voucher-classes:update"},
    {"name": "", "value": "voucher-classes:import"},
    {"name": "", "value": "voucher-classes:delete"},
    {"name": "", "value": "voucher-classes:schedule"},
    {"name": "", "value": "voucher-classes:assign"},

    {"name": "", "value": "admission-cycles:*"},
    {"name": "", "value": "admission-cycles:manage"},
    {"name": "", "value": "admission-cycles:create"},
    {"name": "", "value": "admission-cycles:update"},
    {"name": "", "value": "admission-cycles:import"},
    {"name": "", "value": "admission-cycles:delete"},

    {"name": "", "value": "tariff-instances:*"},
    {"name": "", "value": "tariff-instances:manage"},
    {"name": "", "value": "tariff-instances:create"},
    {"name": "", "value": "tariff-instances:update"},
    {"name": "", "value": "tariff-instances:import"},
    {"name": "", "value": "tariff-instances:delete"},
    {"name": "", "value": "tariff-instances:configure"},

    {"name": "", "value": "admins:*"},
    {"name": "", "value": "admins:manage"},
    {"name": "", "value": "admins:create"},
    {"name": "", "value": "admins:update"},
    {"name": "", "value": "admins:import"},
    {"name": "", "value": "admins:delete"},

    {"name": "", "value": "admission-programmes:*"},
    {"name": "", "value": "admission-programmes:manage"},
    {"name": "", "value": "admission-programmes:create"},
    {"name": "", "value": "admission-programmes:update"},
    {"name": "", "value": "admission-programmes:import"},
    {"name": "", "value": "admission-programmes:delete"},

    {"name": "", "value": "users:*"},
    {"name": "", "value": "users:manage"},
    {"name": "", "value": "users:create"},
    {"name": "", "value": "users:update"},
    {"name": "", "value": "users:import"},
    {"name": "", "value": "users:delete"},

    {"name": "", "value": "staff:*"},
    {"name": "", "value": "staff:manage"},
    {"name": "", "value": "staff:create"},
    {"name": "", "value": "staff:update"},
    {"name": "", "value": "staff:import"},
    {"name": "", "value": "staff:delete"},

    {"name": "", "value": "campuses:*"},
    {"name": "", "value": "campuses:manage"},
    {"name": "", "value": "campuses:create"},
    {"name": "", "value": "campuses:update"},
    {"name": "", "value": "campuses:import"},
    {"name": "", "value": "campuses:delete"},
    {"name": "", "value": "campuses:send"},
    {"name": "", "value": "campuses:print"},

    {"name": "", "value": "vendors:*"},
    {"name": "", "value": "vendors:manage"},
    {"name": "", "value": "vendors:create"},
    {"name": "", "value": "vendors:update"},
    {"name": "", "value": "vendors:import"},
    {"name": "", "value": "vendors:delete"},

    {"name": "", "value": "events:*"},
    {"name": "", "value": "events:manage"},
    {"name": "", "value": "events:create"},
    {"name": "", "value": "events:update"},
    {"name": "", "value": "events:import"},
    {"name": "", "value": "events:delete"},

    {"name": "", "value": "service-orders:*"},
    {"name": "", "value": "service-orders:manage"},
    {"name": "", "value": "service-orders:create"},
    {"name": "", "value": "service-orders:update"},
    {"name": "", "value": "service-orders:import"},
    {"name": "", "value": "service-orders:delete"},

    {"name": "", "value": "service-requests:*"},
    {"name": "", "value": "service-requests:manage"},
    {"name": "", "value": "service-requests:create"},
    {"name": "", "value": "service-requests:update"},
    {"name": "", "value": "service-requests:import"},
    {"name": "", "value": "service-requests:delete"}
] as const;