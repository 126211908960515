import { FC, useEffect } from "react";
import {
  Header,
  MultiCheckInput,
  RichTextArea,
  TextInput,
  UploadArea,
} from "components";
import { gql, useMutation, useQuery } from "@apollo/client";

import _ from "lodash";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";

const UPDATE_CURRENT_STAFF = gql`
  mutation UpdateCurrentStaff($input: UpdateCurrentStaffInput!) {
    updateCurrentStaff(input: $input) {
        staff {
          _id
        }
    }
  }
`;

const GET_CURRENT_STAFF = gql`
query Staff($populate: [String]) {
  currentStaff(populate: $populate) {
    staff {
      _id
      name
      biography
      socials
      coursesTaught
      officeEmail
      officeHours
      officeLocation
      publicationsContent
      researchInterestsContent
      profileImageUrl
      notableProjectsContent
      isHod
      hodMessage
      researchGroups {
        _id
        name
      }
    }
  }
}
`;

interface GetResearchGroupsOutputProps {
  getResearchGroups: {
    _id: string
    name: string
  }[]
}

const GET_RESEARCH_GROUPS = gql`
query GetResearchGroups {
  getResearchGroups {
    _id
    name
  }
}
`

interface GetCurrentStaffOutput {
  currentStaff: {
    staff: {
      _id: string;
      name: string;
      biography: string;
      socials: string[];
      coursesTaught: string;
      officeEmail: string;
      officeHours: string;
      officeLocation: string;
      publicationsContent: string;
      researchInterestsContent: string;
      researchGroups: {
        _id: string
        name: string
      }[]
      profileImageUrl: string;
      notableProjectsContent: string;
      isHod: boolean
      hodMessage: string
    };
  };
}

const PublicationsPage: FC = () => {
  const [updateCurrentStaff, { loading: updating }] = useMutation(UPDATE_CURRENT_STAFF);


  const { data, loading } = useQuery<GetCurrentStaffOutput>(GET_CURRENT_STAFF, {
    variables: {
      "populate": ["researchGroups"]
    },
    fetchPolicy: "no-cache"
  });

  const { data: researchGroups } = useQuery<GetResearchGroupsOutputProps>(GET_RESEARCH_GROUPS)


  const form = useFormik({
    initialValues: {
      profileImageUrl: "",
      name: "",
      biography: "",
      socials: "",
      officeEmail: "",
      officeLocation: "",
      officeHours: "",
      researchInterestsContent: "",
      coursesTaught: "",
      publicationsContent: "",
      notableProjectsContent: "",
      hodMessage: "",
      researchGroups: []
    } as {
      profileImageUrl: string;
      name: string;
      biography: string;
      socials: string;
      officeEmail: string;
      officeLocation: string;
      officeHours: string;
      researchInterestsContent: string;
      coursesTaught: string;
      publicationsContent: string;
      notableProjectsContent: string;
      hodMessage: string;
      researchGroups: string[]
    },
    onSubmit: async (values) => {
      // get the socials from the values and split them by comma to form an array
      const newSocials: string[] = values.socials.split(",").map((social) => social.trim());
      console.log("Publications page", {
        values: {
          ...values,
          socials: newSocials,
        }
      });
      await updateCurrentStaff({
        variables: {
          input: {
            ...values,
            socials: newSocials,
          },
        },
      }).then(({ data }) => {
        if (data.updateCurrentStaff.staff._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Profile Edited Successfully",
            })
          );
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not update profile",
            })
          );
        }
      });
    },
  });

  useEffect(() => {
    console.log({ values: form?.values });
  }, [form?.values]);

  useEffect(() => {
    form.setValues({
      profileImageUrl: data?.currentStaff?.staff?.profileImageUrl || "",
      name: data?.currentStaff?.staff?.name || "",
      biography: data?.currentStaff?.staff?.biography || "",
      socials: data?.currentStaff?.staff?.socials.toString() || "",
      officeEmail: data?.currentStaff?.staff?.officeEmail || "",
      officeLocation: data?.currentStaff?.staff?.officeLocation || "",
      officeHours: data?.currentStaff?.staff?.officeHours || "",
      coursesTaught: data?.currentStaff?.staff?.coursesTaught || "",
      publicationsContent: data?.currentStaff?.staff?.publicationsContent || "",
      researchInterestsContent: data?.currentStaff?.staff?.researchInterestsContent || "",
      notableProjectsContent: data?.currentStaff?.staff?.notableProjectsContent || "",
      hodMessage: data?.currentStaff?.staff?.hodMessage || "",
      researchGroups: data?.currentStaff?.staff?.researchGroups?.map((researchGroup) => researchGroup?._id) as any || []
    });
  }, [data]);

  return (
    <form
      onSubmit={form.handleSubmit}
      onReset={form.handleReset}
      className="flex-1 flex flex-col overflow-hidden h-screen bg-gray-50"
    >
      <Header />
      {/* Primary column */}
      <div className="flex flex-1 flex-col overflow-y-auto p-8 overflow-hidden">
        <div className="divide-y divide-gray-300">
          <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-1 lg:px-8">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Publications
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  We you can list the publications that you have
                </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-1 md:mt-0">
              <div className="bg-white md:col-span-2  shadow sm:rounded px-4 py-5 sm:p-6">
                <div className="mt-5 md:col-span-2 md:mt-0">
                  <div className="col-span-2">
                    <RichTextArea
                      id="publicationsContent"
                      label="Publications"
                      placeholder="eg. Framework for time relevant systems..."
                      {...form}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-white shadow">
        <div className="mx-auto max-w-7xl py-6 px-6 md:flex md:items-center flex flex-row-reverse md:justify-between lg:px-8">
          <button
            type="submit"
            className="inline-flex justify-center rounded border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          >
            {loading || updating ? "Saving..." : "Save"}
          </button>
        </div>
      </footer>
    </form>
  );
};

export default PublicationsPage;
