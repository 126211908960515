import { Outlet, Route } from "react-location";
import {
  ForgotPasswordPage,
  SettingsPage,
  SigninPage,
} from "pages";
import {
  Cog8ToothIcon,
  ArrowRightOnRectangleIcon,
  UserCircleIcon,
  AcademicCapIcon,
  BookOpenIcon,
  ArchiveBoxIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";
import { UserPermission, initiateClearAuth } from "apollo/cache/auth";
import ProfilePage from "pages/profile-page";
import ResearchPage from "pages/research";
import CoursesPage from "pages/courses";
import PublicationsPage from "pages/publications";
import ProjectsPage from "pages/projects";

export type RouteProps = Omit<Route, "children"> & {
  withPermissions?: UserPermission[];
  navigation?: boolean;
  sidebar?: {
    level: "primary" | "secondary";
    label: string;
    icon: any;
    onClick?: () => void;
  };
  children?: RouteProps[];
};

const routes: RouteProps[] = [
  {
    path: "/",
    element: <ProfilePage />,
    sidebar: {
      level: "primary",
      label: "Profile",
      icon: UserCircleIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Profile",
      section: "Single Page",
    },
  },
  {
    path: "/research",
    element: <ResearchPage />,
    sidebar: {
      level: "primary",
      label: "Research",
      icon: AcademicCapIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Research",
      section: "Single Page",
    },
  },
  {
    path: "/courses",
    element: <CoursesPage />,
    sidebar: {
      level: "primary",
      label: "Courses",
      icon: BookOpenIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Courses",
      section: "Single Page",
    },
  },
  {
    path: "/publications",
    element: <PublicationsPage />,
    sidebar: {
      level: "primary",
      label: "Publications",
      icon: ArchiveBoxIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Publications",
      section: "Single Page",
    },
  },
  {
    path: "/projects",
    element: <ProjectsPage />,
    sidebar: {
      level: "primary",
      label: "Projects",
      icon: WrenchScrewdriverIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Projects",
      section: "Single Page",
    },
  },
 
  // {
  //   path: "settings",
  //   element: <SettingsPage />,
  //   sidebar: {
  //     level: "secondary",
  //     label: "Settings",
  //     icon: Cog8ToothIcon
  //   },
  //   meta: {
  //     layout: "App",
  //     breadcrumb: () => "Settings",
  //     section: "Configuration",
  //   }
  // },
  {
    path: "signout",
    element: <Outlet />,
    sidebar: {
      level: "secondary",
      label: "Logout",
      icon: ArrowRightOnRectangleIcon,
      onClick: initiateClearAuth,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Logout",
      section: "Configuration",
    },
  },
  {
    path: "forgot-password",
    element: <ForgotPasswordPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Forgot Password",
    },
  },
  {
    path: "signin",
    element: <SigninPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Signin",
    },
  },
];

export default routes;
