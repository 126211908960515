import { useReactiveVar } from "@apollo/client";
import { FC } from "react";
import { useSearch, MakeGenerics, Navigate, Outlet } from "react-location";
import { currentTokenVar } from 'apollo/cache/auth';

export type AuthLocationGenerics = MakeGenerics<{
  Search: {
    redirect?: string;
  }
}>

const AuthLayout: FC = () => {
  const search = useSearch<AuthLocationGenerics>()
  const currentToken = useReactiveVar(currentTokenVar)

  if (currentToken) {
    // check if theres a token
    // if yes hit server to reauthenticate and redirect to app
    return <Navigate
      to={search?.redirect ?? "/"}
      replace
    />
  }
  return (
    <div className="h-screen">
      <div className="min-h-full flex justify-center items-center relative">
        <img
          className="hidden lg:block absolute left-2 h-full max-w-[250px] bg-center object-cover z-0"
          src={require("assets/great-hall-artwork.webp")}
          alt=""
        />
        <div className="flex flex-col z-10 space-y-10">
          <div className="flex items-center justify-center space-x-2">
            <img
              className="h-12 w-auto"
              src={require("assets/logo-long.png")}
              alt="Waya Money"
            />
          </div>
          <div className="flex-col justify-center p-6 sm:p-10 lg:flex-none bg-white/80 backdrop-blur-xl rounded shadow-md">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthLayout;