import { makeVar } from "@apollo/client";
import Cookies from 'js-cookie'
import config from "config";
import { Permissions } from "apollo/data";
import { toast } from "react-hot-toast";

export const UserRoles = ["SUDO", "DEAN", "ADMISSIONS_OFFICER", "SCHOOL_ADMINISTRATOR"] as const;
export type UserRole = typeof UserRoles[number];
export const UserPermissions = Permissions.map(({value}) => value);
export type UserPermission = typeof UserPermissions[number];
export const PermissionsMap: {[key in UserRole]: UserPermission[]} = {
  SUDO: [
    "*:*"
  ],
  DEAN: [
    "schools:manage",
    "programmes:manage",
    "blocks:manage",
    "rounds:manage",
    "plots:manage",
    "itineraries:manage",
    "properties:manage",
    "customers:manage",
    "voucher-classes:manage",
    "admission-cycles:manage",
    "tariff-instances:manage",
    "admins:manage",
    "admission-programmes:manage",
    "users:manage",
    "campuses:manage",
  ],
  ADMISSIONS_OFFICER: [
    "blocks:manage",
    "rounds:manage",
    "plots:manage",
    "itineraries:manage",
    "properties:manage",
    "customers:manage",
    "voucher-classes:manage",
    "admission-cycles:manage",
    "tariff-instances:manage",
    "admission-programmes:manage",
    "users:manage",
    "campuses:manage",
  ],
  SCHOOL_ADMINISTRATOR: [
    "blocks:manage",
    "rounds:manage",
    "plots:manage",
    "itineraries:manage",
    "properties:manage",
    "customers:manage",
    "voucher-classes:manage",
    "admission-cycles:manage",
    "tariff-instances:manage",
    "admission-programmes:manage",
    "users:manage",
    "campuses:manage",
  ]
}

interface IUser {
  _id: string;
	code: string;
	name: string;
	gender?: "MALE" | "FEMALE";
	phone: string;
	email: string;
	profileImageUrl: string;
	department: {
    _id: string;
    code: string;
    name: string;
    school: {
      _id: string;
      code: string;
      name: string;
    }
  };
	meta: {
		isFirstLogin: boolean;
		isSuspended: boolean;
	};
	role: UserRole;
  permissions: UserPermission[];
	createdAt: Date;
	updatedAt: Date
}

export const isLoggedInVar = makeVar<boolean>(!!Cookies.get(`${config.name}:auth:token`));
export const currentTokenVar = makeVar<string | null>(Cookies.get(`${config.name}:auth:token`) || null);
export const currentUserVar = makeVar<IUser>(JSON.parse(Cookies.get(`${config.name}:auth:user`)??"{}")??null);
export const currentPushTokenVar = makeVar<string | null>(Cookies.get(`${config.name}:auth:push-token`) || null);

export const setAuth = ({user, token}: {user: IUser, token: string}) => {
  const userToSet = {
    ...user,
    permissions: (PermissionsMap as any)[user.role],
  }
  currentUserVar(userToSet);
  Cookies.set(`${config.name}:auth:user`, JSON.stringify(userToSet), { ...config.cookies});
  isLoggedInVar(true);
  currentTokenVar(token);
  Cookies.set(`${config.name}:auth:token`, token, { ...config.cookies, expires: 1 });
}

export const setMe = (user: IUser) => {
  const userToSet = {
    ...user,
    permissions: (PermissionsMap as any)[user.role],
  }
  currentUserVar(userToSet)
  Cookies.set(`${config.name}:auth:user`, JSON.stringify(userToSet), { ...config.cookies })
}

export const setPushToken = (token: any) => {
  currentPushTokenVar(token)
  Cookies.set(`${config.name}:auth:push-token`, token, { ...config.cookies })
}

export const clearAuth = () => {
  isLoggedInVar(false);
  currentTokenVar(null);
  // TODO: handle extra checks for better user experience
  Object.keys(Cookies.get()).forEach((key) => {
    Cookies.remove(key, { ...config.cookies })
  })
}

export const initiateClearAuth = () => {
  toast(JSON.stringify({type: "logout", title: "Are you sure you want to log out?"}), {duration: 1000 * 10, id: "logout"})
}